const config = {
  apiKey: "AIzaSyDPvNqyblCSzc4dHDvv-1ospg0Yi1E7szc",
  authDomain: "imprimeurs3d-974.firebaseapp.com",
  databaseURL: "https://imprimeurs3d-974.firebaseio.com",
  projectId: "imprimeurs3d-974",
  storageBucket: "imprimeurs3d-974.appspot.com",
  messagingSenderId: "21684735130",
  appId: "1:21684735130:web:d443c6d9ac952f0dbf6323",
  measurementId: "G-B1XVH14CP6"
}

let firebaseCache

export const getUiConfig = firebaseApp => ({
  signInFlow: 'popup',
  signInOptions: [
    firebaseApp.auth.GoogleAuthProvider.PROVIDER_ID,
    firebaseApp.auth.EmailAuthProvider.PROVIDER_ID,
  ],
})

const getFirebase = firebaseApp => {
  if (typeof window !== 'undefined') {
    if (firebaseCache) {
      return firebaseCache
    }

    firebaseApp.initializeApp(config)
    firebaseCache = firebaseApp
    return firebaseApp
  }

  return null;
}

export default getFirebase
